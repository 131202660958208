import React from 'react';

interface NavigationItem {
  name: string;
  href: string;
}

interface SocialItem extends NavigationItem {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

interface Navigation {
  Products: NavigationItem[];
  Community: NavigationItem[];
  Resources: NavigationItem[];
  Company: NavigationItem[];
  social: SocialItem[];
}

const navigation: Navigation = {
  Products: [
    { name: 'Terminal', href: 'https://terminal.sharpe.ai/futures/' },
    { name: 'Perpetual DEX', href: 'http://trade.sharpe.ai' },
    { name: 'Bridge', href: 'http://bridge.sharpe.ai' },
    { name: 'Earn', href: 'https://earn.sharpe.ai/' }
  ],
  Community: [
    { name: 'Twitter', href: 'https://x.com/SharpeLabs' },
    { name: 'Discord', href: 'https://discord.com/invite/tFAvMTw6Hx' },
    { name: 'DeBank', href: 'https://debank.com/official/Sharpe_AI/' },
    { name: 'Galxe', href: 'http://galxe.com/sharpelabs' }
  ],
  Resources: [
    { name: 'Documentation', href: 'https://docs.sharpe.ai/' },
    { name: 'Blog', href: 'https://sharpeai.medium.com/' },
    { name: 'Whitepaper', href: 'https://github.com/Sharpelabs/Whitepaper' },
    { name: 'Governance', href: 'https://docs.sharpe.ai/introduction/governance' }
  ],
  Company: [
    { name: 'Support', href: 'mailto:team@sharpe.ai' },
    { name: 'Security', href: 'https://github.com/Sharpelabs/Security' },
    {
      name: 'Media Kit',
      href: 'https://profuse-hurricane-790.notion.site/01edfa01ea9343a1aa96bb0d77132417?v=5e9cb96640244b2e921204ab112b82a1&pvs=74'
    }
  ],
  social: [
    {
      name: 'Email',
      href: 'mailto:team@sharpe.ai',
      icon: (props: React.SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M12 13.35l-11.5-6.95v10.1c0 .83.67 1.5 1.5 1.5h20c.83 0 1.5-.67 1.5-1.5v-10.1l-11.5 6.95zm0-2.7l11.5-6.65h-23l11.5 6.65z" />
        </svg>
      )
    },
    {
      name: 'X',
      href: 'https://x.com/SharpeLabs',
      icon: (props: React.SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      )
    },
    {
      name: 'Discord',
      href: 'https://discord.com/invite/tFAvMTw6Hx',
      icon: (props: React.SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M20.317 4.3698C18.7311 3.7525 17.0725 3.2808 15.3611 2.9733C15.3193 2.9655 15.2776 2.981 15.2546 3.0155C14.8772 3.578 14.4505 4.2965 14.1816 4.7524C12.4193 4.493 10.6262 4.493 8.8621 4.7524C8.5927 4.2965 8.1685 3.578 7.7908 3.0155C7.7681 2.981 7.7262 2.9655 7.6846 2.9733C5.9727 3.2812 4.3139 3.753 2.728 4.3698C2.6936 4.3835 2.6652 4.4103 2.6496 4.4458C0.1632 8.9033 -0.3204 13.2416 0.0994 17.5118C0.1014 17.5325 0.111 17.552 0.126 17.5665C2.2815 19.402 4.3474 20.323 6.3776 20.9985C6.4195 21.0122 6.4641 20.9962 6.4863 20.957C6.8748 20.2378 7.2114 19.4798 7.4869 18.692C7.5015 18.647 7.4784 18.5992 7.4314 18.582C6.681 18.319 5.9686 17.9886 5.306 17.5952C5.2667 17.5708 5.264 17.5128 5.3012 17.4847C5.679 17.2144 6.0566 16.9425 6.4227 16.6545C6.452 16.6317 6.492 16.624 6.5281 16.633C10.168 17.5563 13.7824 17.5563 17.4073 16.633C17.4434 16.6237 17.4834 16.6317 17.5128 16.6545C17.8794 16.9429 18.257 17.2148 18.6348 17.4847C18.672 17.5128 18.6693 17.5708 18.63 17.5952C17.9675 17.9891 17.2551 18.3195 16.5042 18.582C16.4572 18.5992 16.4343 18.647 16.4486 18.692C16.7246 19.4798 17.0618 20.2378 17.4499 20.957C17.4717 20.9962 17.5163 21.0122 17.5582 20.9985C19.5888 20.3226 21.6543 19.4015 23.8098 17.5665C23.8251 17.552 23.8344 17.5325 23.8365 17.5118C24.2992 12.096 22.8423 7.7668 20.3656 4.4458C20.3506 4.4103 20.3222 4.3835 20.2878 4.3698ZM8.1012 15.6298C7.1174 15.6298 6.3062 14.661 6.3062 13.4985C6.3062 12.336 7.0891 11.367 8.1012 11.367C9.1133 11.367 9.9324 12.336 9.9103 13.4985C9.9103 14.661 9.1133 15.6298 8.1012 15.6298ZM15.885 15.6298C14.9012 15.6298 14.09 14.661 14.09 13.4985C14.09 12.336 14.8729 11.367 15.885 11.367C16.8971 11.367 17.7162 12.336 17.6941 13.4985C17.6941 14.661 16.8971 15.6298 15.885 15.6298Z" />
        </svg>
      )
    },
    {
      name: 'Telegram',
      href: 'https://t.me/SharpeAI_Official',
      icon: (props: React.SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M20.6652 3.7168L2.93479 10.5508C1.72479 11.0408 1.73145 11.7168 2.71479 12.0168L7.26145 13.4368L17.7981 6.7908C18.2981 6.4868 18.7515 6.6528 18.3781 6.9828L9.84145 14.6848H9.83979L9.84145 14.6855L9.52814 19.3778C9.98145 19.3778 10.1781 19.1708 10.4281 18.9288L12.6681 16.7428L17.2615 20.1638C18.1048 20.6308 18.7165 20.3908 18.9281 19.3795L21.9468 5.1518C22.2568 3.9118 21.4748 3.3508 20.6652 3.7168Z" />
        </svg>
      )
    }
  ]
};

export const Footer = () => {
  return (
    <footer className="bg-[#0A0A0A]" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-4 lg:px-8 lg:pt-12">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="">
            <a href="https://sharpe.ai/">
              <img className="h-14 w-28" src="/sharpe-new-logo.svg" alt="Company name" />
            </a>
            <p className="text-[14px] mt-2 text-gray-300">
              Sharpe AI is the Frontend for Intelligence, Tracking and Investing your Digital Assets.
            </p>
            <div className="flex mt-4 space-x-6">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-gray-400">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-[14px] font-semibold text-white">Products</h3>
                <ul role="list" className="mt-6 space-y-2">
                  {navigation.Products.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} target="_self" className="text-[14px] text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-[14px] font-semibold leading-2 text-white">Community</h3>
                <ul role="list" className="mt-6 space-y-2">
                  {navigation.Community.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} target="_blank" rel="noopener noreferrer" className="text-[14px] text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-[14px] font-semibold text-white">Resources</h3>
                <ul role="list" className="mt-6 space-y-2">
                  {navigation.Resources.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} target="_blank" rel="noopener noreferrer" className="text-[14px] text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-[14px] font-semibold text-white">Company</h3>
                <ul role="list" className="mt-6 space-y-2">
                  {navigation.Company.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} target="_blank" rel="noopener noreferrer" className="text-[14px] text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};